<template>
  <div class="success-page-wrap">
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Add new bank</div>
      <div class="main has-background-white has-text-centered">
        <div class="has-text-centered has-text-weight-semibold link-account">Thanks for linking your account!</div>
        <div :class="`main-content has-text-centered ${flow === 'manual' ? 'more-padding' : ''}`" v-html="mainContent"></div>
        <img src="@assets/images/wallet/success-check.png" alt="success" class="success-check">
      </div>
      <BottomButtons
        backButtonText="Finish"
        nextButtonText="Add Funds"
        :nextDisabled="false"
        :showSteps="false"
        @goNext="addFunds"
        @goBack="finish"
      ></BottomButtons>
    </div>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import walletRoutes from '@utils/mixins/wallet-routes'

export default {
  mixins: [walletRoutes],
  components: {
    MenuAndLogo,
    BottomButtons,
  },
  computed: {
    flow() {
      return this.$route.query.type
    },
    mainContent() {
      return this.flow === 'PLAID' ? 'You have successfully linked your bank account! Your next step is to add some funds from your account into your addy wallet.' :
        'It may take up to 24 hours to verify and successfully link your bank account.<br/>Check back soon!'
    }
  },
  methods: {
    finish() {
      this.$router.push('/wallet')
    },
    addFunds() {
      this.toAddFundsPage()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.success-page-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-bottom: 80px;
  min-height: 100vh;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      height: 426px;
      border-radius: 12px;
      box-shadow: 0px 20px 40px rgba(215, 145, 51, 0.15);
      margin-bottom: 30px;
      padding: 0 13px;
      .link-account {
        font-size: 16px;
        padding-top: 24px;
        padding-bottom: 8px;
      }
      .main-content {
        padding-top: 8px;
        font-size: 16px;
        line-height: 19px;
        &.more-padding {
          padding: 0 10px;
        }
      }
      .success-check {
        width: 198px;
        margin-top: 49px;
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .success-page-wrap {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
